import React, { useEffect, useState } from "react"
import { Script } from "gatsby"
import { Container } from "react-bootstrap"

const ElfSightReview = (props) => {
  const [renderComponent, setRenderComponent] = useState(false)
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

    setTimeout(() => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    }, 5000)
  }, [])
  return (
    <section className="elfsight-review-wrapper section-p">
      <Container>
        <div className={props.code} data-elfsight-app-lazy></div>
      </Container>
      {renderComponent && <Script src="https://apps.elfsight.com/p/platform.js" strategy="idle" />}
    </section>
  )
}

export default ElfSightReview
