import { graphql, useStaticQuery } from "gatsby"

const useConfig = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          show_areaguide_banner_ctas
          content {
            areaguide_detail_sidebar {
              title
              content
            }
          }
          elfSight {
            review
            review_carousel
            review_badge
            review_page
          }
        }
      }
    }
  `)
  const { show_areaguide_banner_ctas, content, elfSight } = data?.site?.siteMetadata
  return {
    show_areaguide_banner_ctas: show_areaguide_banner_ctas,
    content: content,
    elfSight: elfSight
  }
}

export default useConfig
